<template>
  <div class="pt-4 pl-3">
    <span>
      <v-icon x-large>mdi-arrow-left-bold</v-icon> Please select a project!
    </span>
  </div>
</template>

<script>
export default {
  name: "WelcomeView",
  mounted() {

/*    this.$store.dispatch('dfg/loadMySurvey').then(() => {
      if (!this.$store.getters['dfg/isSubmittedForCurrentYear']) {
        this.$router.push({name: "dfg"})
      }
    })*/

  }
}
</script>

<style scoped>

</style>
